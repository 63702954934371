import React, {useRef, useState, useEffect } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { faCompass, faUser } from '@fortawesome/free-regular-svg-icons';
import { faMagicWandSparkles, faPeopleGroup, faSitemap, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NewSearchBar.module.css';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { TagsInput } from "react-tag-input-component";
import { getNames } from 'country-list';
import axios from 'axios';
import avatarSearch from "../../assets/images/avatar_search.png"
import onlineBadge from "../../assets/images/online_badge.png"
import barChart from "../../assets/images/bar-chart-square.png"
import { INDUSTRY } from '../../constant/industry';
import { FUNDING } from '../../constant/funding';
import Logo from '../../assets/images/Logo_wrap.png';
import brainHead from "../../assets/images/blinkhead.png"

const NewSearchBar = () => {
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [funding, setFunding] = useState([]);
    const [formValues, setFormValues] = useState({
        person_locations: [],
        organization_num_employees_ranges: [],
        person_titles: [],
        organization_industry_tag_ids: [],
        organization_latest_funding_stage_cd: [],
        revenue_range : {"max" : null, "min" : null}
        // q_organization_keyword_tags: []
    });
    const [searchLoading, setSearchLoading] = useState(false);
    const [startPromptSearch, setStartPromptSearch] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [currentFormScreen, setCurrentFormScreen] = useState("input");
    const [totalEntries, setTotalEntries] = useState(0);
    const [magicIo, setMagicIo] = useState(false);
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [modalPrompt, setModalPrompt] = useState(''); // Prompt input state
    const [isModalLoading, setIsModalLoading] = useState(false); // Modal loader state
    const [showSendLoading, setShowLoading] = useState(false); // Modal loader state
    const btnRef = useRef(null);
    const dummyMessage = {1: "Sure. Any other details?",
                        3 : "I'll suggest a few leads to you now."}

    const [messages, setMessages] = useState([
        { sender: 'Actio AI', text: 'Hey Bahir, how can I help you today?', timestamp: 'Today 2:20pm' },
      ]);
      const [inputText, setInputText] = useState('');
      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
        if (startPromptSearch && btnRef.current){
            btnRef.current.click();
        } 
    }, [startPromptSearch]);


      const handleSendMessage = async () => {
        if (inputText.trim() === '') return;
        
        setShowLoading(true);
        setIsLoading(true);
      
        const newMessage = {
          sender: 'You',
          text: inputText,
          timestamp: 'Just now',
        };
      
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputText('');
      
        const url = `${process.env.REACT_APP_API_URL}/user-prompt`;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        const payload = { user_prompt: inputText };
      
        try {
            setIsLoading(true);
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${user.id}@@@${user.email}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          setIsLoading(false);
          setShowLoading(false);
          Object.keys(data.data).forEach(key => {
            
            const value = data.data[key];
            handleInputChange(key, value);
          });
         
        } catch (error) {
          toast.error('Error fetching emails');
          console.error('Error fetching emails:', error);
          return [];
        } finally {
            setStartPromptSearch(true)
          
        }
      };

    
      const fetchEmails = async (status, page, leadId) => {
        const pageSize = 1; // Adjust the page size if needed
        const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}?page=${page}&page_size=${pageSize}`;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${user.id}@@@${user.email}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          return data.data.total_items || 0;
        } catch (error) {
          toast.error('Error fetching emails');
          console.error('Error fetching emails:', error);
          return [];
        }
      };
      

    const handleInputChange = (name, value) => {
        setFormValues(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSearchLoading(true);
        const allEmpty = Object.values(formValues).every(arr => arr.length === 0);
        if (allEmpty) {
            toast.error('All values are empty.');
            setSearchLoading(false);
            return;
        }

        var data = formValues;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        data.page_number = 1;
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/find_people', data, {
                headers: {
                    Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                }
            });

            setTotalEntries(response.data.page_data.total_entries);
            setIsFormSubmitted(true); // Set form submission state to true
            setSearchLoading(false);

            const leadId = response.data.lead_search_uuid;
            let page = 1; // Starting page for fetching emails
            let emailInterval;

            // Function to periodically fetch emails and redirect if needed
            const checkEmails = async () => {
                const emails = await fetchEmails('status', page, leadId);

                if (emails >= 2) {
                    // Clear the interval and redirect if emails are found
                    clearInterval(emailInterval);
                    window.location.href = `/lead-history/${leadId}`;
                } else {
                    // No emails found yet, increment page for next call
                    page += 1;
                }
            };

            // Set up an interval to check emails every 8 seconds
            emailInterval = setInterval(checkEmails, 3000);


            return true; // Indicate success
        } catch (error) {
            console.log(error);
            setSearchLoading(false);
            toast.error(error.response.data.detail);
            return false; // Indicate failure
        }
    };

    // const handleModalSubmit = () => {
    //     setIsModalLoading(true);
        
    //     // Simulate a delay before updating form values
    //     setTimeout(() => {
    //         // Update the form values after 5 seconds
    //         setFormValues(prevState => ({
    //             ...prevState,
    //             person_locations: [
    //                 "United Kingdom" // Set desired value here
    //             ],
    //             organization_num_employees_ranges: [
    //                 "51, 100" // Set desired value here
    //             ],
    //             person_titles: [
    //                 "Sales Manager" // Set desired value here
    //             ],
    //             q_organization_keyword_tags: [
    //                 "SaaS" // Set desired value here
    //             ],
    //             revenue_range : {"max" : null, "min" : null}
    //         }));
    
    //         // Simulate a delay to show the loading spinner
    //         setTimeout(() => {
    //             setIsModalLoading(false);
    //             setShowModal(false);
    //             // Optionally trigger form submission if needed
    //             // handleSubmit();
    //         }, 100); // Adjust delay as needed
    
    //     }, 100); // Delay before updating form values
    // };

    const employee_range = [
        { value: '1, 10', label: '1 - 10' },
        { value: '11, 20', label: '11 - 20' },
        { value: '21, 50', label: '21 - 50' },
        { value: '51, 100', label: '51 - 100' },
        { value: '101, 200', label: '101 - 200' },
        { value: '201, 500', label: '201 - 500' },
        { value: '501, 1000', label: '501 - 1,000' },
        { value: '1001, 2000', label: '1,001 - 2,000' },
        { value: '2001, 5000', label: '2,001 - 5,000' },
        { value: '5001, 10000', label: '5,001 - 10,000' },
        { value: '10000+', label: '10,000+' }
    ];

    useEffect(() => {
        const countryNames = [
            {"value": "Afghanistan", "label": "Afghanistan"},
            {"value": "Albania", "label": "Albania"},
            {"value": "Algeria", "label": "Algeria"},
            {"value": "Andorra", "label": "Andorra"},
            {"value": "Angola", "label": "Angola"},
            {"value": "Antigua and Barbuda", "label": "Antigua and Barbuda"},
            {"value": "Argentina", "label": "Argentina"},
            {"value": "Armenia", "label": "Armenia"},
            {"value": "Australia", "label": "Australia"},
            {"value": "Austria", "label": "Austria"},
            {"value": "Azerbaijan", "label": "Azerbaijan"},
            {"value": "Bahamas", "label": "Bahamas"},
            {"value": "Bahrain", "label": "Bahrain"},
            {"value": "Bangladesh", "label": "Bangladesh"},
            {"value": "Barbados", "label": "Barbados"},
            {"value": "Belarus", "label": "Belarus"},
            {"value": "Belgium", "label": "Belgium"},
            {"value": "Belize", "label": "Belize"},
            {"value": "Benin", "label": "Benin"},
            {"value": "Bhutan", "label": "Bhutan"},
            {"value": "Bolivia", "label": "Bolivia"},
            {"value": "Bosnia and Herzegovina", "label": "Bosnia and Herzegovina"},
            {"value": "Botswana", "label": "Botswana"},
            {"value": "Brazil", "label": "Brazil"},
            {"value": "Brunei", "label": "Brunei"},
            {"value": "Bulgaria", "label": "Bulgaria"},
            {"value": "Burkina Faso", "label": "Burkina Faso"},
            {"value": "Burundi", "label": "Burundi"},
            {"value": "Cabo Verde", "label": "Cabo Verde"},
            {"value": "Cambodia", "label": "Cambodia"},
            {"value": "Cameroon", "label": "Cameroon"},
            {"value": "Canada", "label": "Canada"},
            {"value": "Central African Republic", "label": "Central African Republic"},
            {"value": "Chad", "label": "Chad"},
            {"value": "Chile", "label": "Chile"},
            {"value": "China", "label": "China"},
            {"value": "Colombia", "label": "Colombia"},
            {"value": "Comoros", "label": "Comoros"},
            {"value": "Congo (Congo-Brazzaville)", "label": "Congo (Congo-Brazzaville)"},
            {"value": "Congo (Democratic Republic)", "label": "Congo (Democratic Republic)"},
            {"value": "Costa Rica", "label": "Costa Rica"},
            {"value": "Croatia", "label": "Croatia"},
            {"value": "Cuba", "label": "Cuba"},
            {"value": "Cyprus", "label": "Cyprus"},
            {"value": "Czechia", "label": "Czechia"},
            {"value": "Denmark", "label": "Denmark"},
            {"value": "Djibouti", "label": "Djibouti"},
            {"value": "Dominica", "label": "Dominica"},
            {"value": "Dominican Republic", "label": "Dominican Republic"},
            {"value": "Ecuador", "label": "Ecuador"},
            {"value": "Egypt", "label": "Egypt"},
            {"value": "El Salvador", "label": "El Salvador"},
            {"value": "Equatorial Guinea", "label": "Equatorial Guinea"},
            {"value": "Eritrea", "label": "Eritrea"},
            {"value": "Estonia", "label": "Estonia"},
            {"value": "Eswatini (Swaziland)", "label": "Eswatini (Swaziland)"},
            {"value": "Ethiopia", "label": "Ethiopia"},
            {"value": "Fiji", "label": "Fiji"},
            {"value": "Finland", "label": "Finland"},
            {"value": "France", "label": "France"},
            {"value": "Gabon", "label": "Gabon"},
            {"value": "Gambia", "label": "Gambia"},
            {"value": "Georgia", "label": "Georgia"},
            {"value": "Germany", "label": "Germany"},
            {"value": "Ghana", "label": "Ghana"},
            {"value": "Greece", "label": "Greece"},
            {"value": "Grenada", "label": "Grenada"},
            {"value": "Guatemala", "label": "Guatemala"},
            {"value": "Guinea", "label": "Guinea"},
            {"value": "Guinea-Bissau", "label": "Guinea-Bissau"},
            {"value": "Guyana", "label": "Guyana"},
            {"value": "Haiti", "label": "Haiti"},
            {"value": "Honduras", "label": "Honduras"},
            {"value": "Hungary", "label": "Hungary"},
            {"value": "Iceland", "label": "Iceland"},
            {"value": "India", "label": "India"},
            {"value": "Indonesia", "label": "Indonesia"},
            {"value": "Iran", "label": "Iran"},
            {"value": "Iraq", "label": "Iraq"},
            {"value": "Ireland", "label": "Ireland"},
            {"value": "Israel", "label": "Israel"},
            {"value": "Italy", "label": "Italy"},
            {"value": "Jamaica", "label": "Jamaica"},
            {"value": "Japan", "label": "Japan"},
            {"value": "Jordan", "label": "Jordan"},
            {"value": "Kazakhstan", "label": "Kazakhstan"},
            {"value": "Kenya", "label": "Kenya"},
            {"value": "Kiribati", "label": "Kiribati"},
            {"value": "Korea (North)", "label": "Korea (North)"},
            {"value": "Korea (South)", "label": "Korea (South)"},
            {"value": "Kuwait", "label": "Kuwait"},
            {"value": "Kyrgyzstan", "label": "Kyrgyzstan"},
            {"value": "Laos", "label": "Laos"},
            {"value": "Latvia", "label": "Latvia"},
            {"value": "Lebanon", "label": "Lebanon"},
            {"value": "Lesotho", "label": "Lesotho"},
            {"value": "Liberia", "label": "Liberia"},
            {"value": "Libya", "label": "Libya"},
            {"value": "Liechtenstein", "label": "Liechtenstein"},
            {"value": "Lithuania", "label": "Lithuania"},
            {"value": "Luxembourg", "label": "Luxembourg"},
            {"value": "Madagascar", "label": "Madagascar"},
            {"value": "Malawi", "label": "Malawi"},
            {"value": "Malaysia", "label": "Malaysia"},
            {"value": "Maldives", "label": "Maldives"},
            {"value": "Mali", "label": "Mali"},
            {"value": "Malta", "label": "Malta"},
            {"value": "Marshall Islands", "label": "Marshall Islands"},
            {"value": "Mauritania", "label": "Mauritania"},
            {"value": "Mauritius", "label": "Mauritius"},
            {"value": "Mexico", "label": "Mexico"},
            {"value": "Micronesia", "label": "Micronesia"},
            {"value": "Moldova", "label": "Moldova"},
            {"value": "Monaco", "label": "Monaco"},
            {"value": "Mongolia", "label": "Mongolia"},
            {"value": "Montenegro", "label": "Montenegro"},
            {"value": "Morocco", "label": "Morocco"},
            {"value": "Mozambique", "label": "Mozambique"},
            {"value": "Myanmar (Burma)", "label": "Myanmar (Burma)"},
            {"value": "Namibia", "label": "Namibia"},
            {"value": "Nauru", "label": "Nauru"},
            {"value": "Nepal", "label": "Nepal"},
            {"value": "Netherlands", "label": "Netherlands"},
            {"value": "New Zealand", "label": "New Zealand"},
            {"value": "Nicaragua", "label": "Nicaragua"},
            {"value": "Niger", "label": "Niger"},
            {"value": "Nigeria", "label": "Nigeria"},
            {"value": "North Macedonia", "label": "North Macedonia"},
            {"value": "Norway", "label": "Norway"},
            {"value": "Oman", "label": "Oman"},
            {"value": "Pakistan", "label": "Pakistan"},
            {"value": "Palau", "label": "Palau"},
            {"value": "Panama", "label": "Panama"},
            {"value": "Papua New Guinea", "label": "Papua New Guinea"},
            {"value": "Paraguay", "label": "Paraguay"},
            {"value": "Peru", "label": "Peru"},
            {"value": "Philippines", "label": "Philippines"},
            {"value": "Poland", "label": "Poland"},
            {"value": "Portugal", "label": "Portugal"},
            {"value": "Qatar", "label": "Qatar"},
            {"value": "Romania", "label": "Romania"},
            {"value": "Russia", "label": "Russia"},
            {"value": "Rwanda", "label": "Rwanda"},
            {"value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis"},
            {"value": "Saint Lucia", "label": "Saint Lucia"},
            {"value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines"},
            {"value": "Samoa", "label": "Samoa"},
            {"value": "San Marino", "label": "San Marino"},
            {"value": "Sao Tome and Principe", "label": "Sao Tome and Principe"},
            {"value": "Saudi Arabia", "label": "Saudi Arabia"},
            {"value": "Senegal", "label": "Senegal"},
            {"value": "Serbia", "label": "Serbia"},
            {"value": "Seychelles", "label": "Seychelles"},
            {"value": "Sierra Leone", "label": "Sierra Leone"},
            {"value": "Singapore", "label": "Singapore"},
            {"value": "Slovakia", "label": "Slovakia"},
            {"value": "Slovenia", "label": "Slovenia"},
            {"value": "Solomon Islands", "label": "Solomon Islands"},
            {"value": "Somalia", "label": "Somalia"},
            {"value": "South Africa", "label": "South Africa"},
            {"value": "South Sudan", "label": "South Sudan"},
            {"value": "Spain", "label": "Spain"},
            {"value": "Sri Lanka", "label": "Sri Lanka"},
            {"value": "Sudan", "label": "Sudan"},
            {"value": "Suriname", "label": "Suriname"},
            {"value": "Sweden", "label": "Sweden"},
            {"value": "Switzerland", "label": "Switzerland"},
            {"value": "Syria", "label": "Syria"},
            {"value": "Taiwan", "label": "Taiwan"},
            {"value": "Tajikistan", "label": "Tajikistan"},
            {"value": "Tanzania", "label": "Tanzania"},
            {"value": "Thailand", "label": "Thailand"},
            {"value": "Timor-Leste", "label": "Timor-Leste"},
            {"value": "Togo", "label": "Togo"},
            {"value": "Tonga", "label": "Tonga"},
            {"value": "Trinidad and Tobago", "label": "Trinidad and Tobago"},
            {"value": "Tunisia", "label": "Tunisia"},
            {"value": "Turkey", "label": "Turkey"},
            {"value": "Turkmenistan", "label": "Turkmenistan"},
            {"value": "Tuvalu", "label": "Tuvalu"},
            {"value": "Uganda", "label": "Uganda"},
            {"value": "Ukraine", "label": "Ukraine"},
            {"value": "United Arab Emirates", "label": "United Arab Emirates"},
            {"value": "United Kingdom", "label": "United Kingdom"},
            {"value": "United States", "label": "United States"},
            {"value": "Uruguay", "label": "Uruguay"},
            {"value": "Uzbekistan", "label": "Uzbekistan"},
            {"value": "Vanuatu", "label": "Vanuatu"},
            {"value": "Vatican City", "label": "Vatican City"},
            {"value": "Venezuela", "label": "Venezuela"},
            {"value": "Vietnam", "label": "Vietnam"},
            {"value": "Yemen", "label": "Yemen"},
            {"value": "Zambia", "label": "Zambia"},
            {"value": "Zimbabwe", "label": "Zimbabwe"}
        ]
        
        // const countryOptions = countryNames.map(name => ({ value: name, label: name }));
        setCountries(countryNames);
    }, []);

    useEffect(() => {
        const industryOptions = INDUSTRY
            .sort((a, b) => a.cleaned_name.localeCompare(b.cleaned_name)) // Sort alphabetically by cleaned_name
            .map(name => ({ value: name.id, label: name.cleaned_name }));
            
        setIndustries(industryOptions);
    }, []);

    useEffect(() =>{
        const fundingOptions = FUNDING.map(name => ({ value: name.value, label: name.display_name }));
        setFunding(fundingOptions)
    }, [])


    return (
        <div className={styles.searchBarContainerMain}>
             <div className={styles.searchBarContainer}>
             {isFormSubmitted ? (
                <div className={styles.formContainerSubmittedstyles}>
                    <div className={styles.successMessage}>
                        <div className={styles.loaderContainer}>
                            <Spinner animation="border" variant="primary" className={styles.spinner} />
                        </div>
                        <h3 className={styles.successTitle}>Search In Progress</h3>
                        <p className={styles.successText}>
                            We have found <strong>{totalEntries} potential leads</strong> for you.
                        </p>
                        <p className={styles.secondaryText}>
                            We're now processing your request. Please hang tight for a few moments...
                        </p>
                        <p className={styles.secondaryText}>
                            You will be redirected shortly.
                        </p>
                    </div>
                    </div>): 
                    <div className={styles.formContainerPedning}>
                    <div className={styles.formContainerWrapper}>
                    {currentFormScreen == "input" ?
                    <div className={styles.formInputs}>
                        <div className={styles.formHeader}>
                                <span>
                                <img src={barChart} alt="Bar graph" />
                                </span>
                                <span className={styles.findLeadsLabel}>
                                    Find Leads
                                </span>
                        </div>

                    <Form onSubmit={handleSubmit} className={styles.formUiContainer}>
                            <div className={styles.formUi}>
                            <Form.Group className={styles.singleForm}>
                                <div>
                                    <Form.Label>
                                        <span className={styles.formLabel}>
                                            <FontAwesomeIcon icon={faCompass} className={styles.fontIcons} />Location
                                        </span>
                                    </Form.Label>
                                    <CreatableSelect
                                        isMulti
                                        options={countries}
                                        onChange={selectedOptions => handleInputChange('person_locations', selectedOptions.map(option => option.value))}
                                        className={styles.inputForm}
                                        styles={{
                                            multiValue: (base) => ({
                                                ...base,
                                                backgroundColor: 'transparent', // or choose your desired background color
                                                border : '1px solid #D0D5DD',
                                                borderRadius: '8px',
                                            }),
                                            multiValueLabel: (base) => ({
                                                ...base,
                                                color: 'inherit', // inherit text color
                                            }),
                                            multiValueRemove: (base) => ({
                                                ...base,
                                                ':hover': {
                                                    backgroundColor: 'transparent', // remove hover background color
                                                    color: 'red', // change remove icon color on hover if needed
                                                }
                                            }),
                                        }}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className={styles.singleForm}>
                                <div>
                                    <Form.Label>
                                        <span className={styles.formLabel}>
                                            <FontAwesomeIcon icon={faPeopleGroup} className={styles.fontIcons} />Team Size
                                        </span>
                                    </Form.Label>
                                    <Select
                                            isMulti
                                            options={employee_range}
                                            onChange={selectedOptions => handleInputChange('organization_num_employees_ranges', selectedOptions.map(option => option.value))}
                                            className={styles.inputForm}
                                            styles={{
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'transparent', // customize background color
                                                    border : '1px solid #D0D5DD',
                                                    borderRadius: '8px',
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'inherit', // inherit text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    ':hover': {
                                                        backgroundColor: 'transparent',
                                                        color: 'red',
                                                    }
                                                }),
                                            }}
                                        />
                                </div>
                            </Form.Group>

                            <Form.Group className={styles.singleForm}>
                                <div>
                                    <Form.Label>
                                        <span className={styles.formLabel}>
                                            <FontAwesomeIcon icon={faUser} className={styles.fontIcons} />Title
                                        </span>
                                    </Form.Label>
                                    <TagsInput
                                        // value={formValues.person_titles}
                                        placeHolder='Enter Titles'
                                        onChange={value => handleInputChange('person_titles', value)}
                                        separators={["Enter"]}
                                        className={styles.inputForm}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className={styles.singleForm}>
                                <div>
                                    <Form.Label>
                                        <span className={styles.formLabel}>
                                            <FontAwesomeIcon icon={faSitemap} className={styles.fontIcons} />Industry
                                        </span>
                                    </Form.Label>
                                    <Select
                                            isMulti
                                            options={industries}
                                            onChange={selectedOptions => handleInputChange('organization_industry_tag_ids', selectedOptions.map(option => option.value))}
                                            className={styles.inputForm}
                                            styles={{
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'transparent', // customize background color
                                                    border : '1px solid #D0D5DD',
                                                    borderRadius: '8px',
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'inherit', // inherit text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    ':hover': {
                                                        backgroundColor: 'transparent',
                                                        color: 'red',
                                                    }
                                                }),
                                            }}
                                        />
                                </div>
                            </Form.Group>
                        
                            <Form.Group className={styles.singleForm}>
                                <div>
                                    <Form.Label>
                                        <span className={styles.formLabel}>
                                            <FontAwesomeIcon icon={faCashRegister} className={styles.fontIcons} />Funding
                                        </span>
                                    </Form.Label>
                                    <Select
                                            isMulti
                                            options={funding}
                                            onChange={selectedOptions => handleInputChange('organization_latest_funding_stage_cd', selectedOptions.map(option => option.value))}
                                            className={styles.inputForm}
                                            styles={{
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'transparent', // customize background color
                                                    border : '1px solid #D0D5DD',
                                                    borderRadius: '8px',
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'inherit', // inherit text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    ':hover': {
                                                        backgroundColor: 'transparent',
                                                        color: 'red',
                                                    }
                                                }),
                                            }}
                                        />
                                </div>
                            </Form.Group>
                            


                        </div>
                            <div className={styles.SubmitBtn}>
                                <Button ref={btnRef} variant="primary rounded-pill" type="submit" disabled={searchLoading} className={styles.submitBtnContainer}>
                                    {searchLoading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-search"></i> <span className={{ marginLeft: "5px" }}>Search Leads</span></>}
                                </Button>
                            </div>

                            {/* <strong> OR </strong> */}

                            <div className={styles.ChangeView} onClick={() =>{
                                setCurrentFormScreen("AIMode")
                            }}>
                                Switch to AI mode
                                
                            </div>
                        
                    </Form>
                    </div>
                    :
                    <div className={styles.magicIo}>
                        <div className={styles.formHeader}>
                            <img src = {Logo} className={styles.ActIoAgentLogo}/>
                            Chat with Acto AI
                            <span>
                            <img src = {onlineBadge} className={styles.Onlinelogo}/>

                            </span>

                        </div>
                        <div className={styles.chatContainer}>
                            <div className={styles.chatMsgContainer}>
                            {messages.map((message, index) => (
                                message.sender === "Actio AI" ?
                                <div className={styles.messageCard}>
                                <img src={brainHead} alt="Profile" className={styles.profileImage} />
                                <div className={styles.messageContent}>
                                    <div className={styles.messageHeader}>
                                    <span className={styles.senderName}>Actio AI</span>
                                    {/* <span className={styles.messageTimestamp}>2:30</span> */}
                                    </div>
                                    <div className={styles.messageText}>{message.text}</div>
                                </div>
                                </div> :
                                <div className={styles.messageCardUser}>
                                <div className={styles.messageContentUser}>
                                    <div className={styles.messageHeader}>
                                    <span className={styles.senderName}>You</span>
                                    {/* <span className={styles.messageTimestamp}>2:30</span> */}
                                    </div>
                                    <div className={styles.messageText}>{message.text}</div>
                                </div>
                                </div>

                            ))}
                            {isLoading && (
                                <div className={styles.messageContainer}>
                                <div className={styles.sender}>Actio AI</div>
                                <div className={styles.messageText}>...</div>
                                </div>
                            )}
                            </div>
                            <div>

                            <div className={styles.ChatSubmitBtn}>
                            <input
                                type="text"
                                placeholder="Write to Actio to get help"
                                className={styles.input}
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                                onKeyPress={(e) => {
                                if (e.key === 'Enter') handleSendMessage();
                                }}
                            />
                            <button disabled = {showSendLoading} className={styles.sendButton} onClick={handleSendMessage}>Send</button>
                            
                            </div>
                            <div className={styles.ChangeViewAI} onClick={() =>{
                                setCurrentFormScreen("input")
                            }}>
                                Switch to Search mode
                                
                            </div>
                            </div>
                            
                            </div>
                            
                            
                        </div>
                        }
                </div>
                </div>}

            </div>

                    </div>
    );
};

export default NewSearchBar;
